@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.supportSwitchOn {
		@apply bg-primary rounded-t-primary bg-opacity-40;
	}

	.supportSwitchOff {
		@apply bg-primary rounded-t-primary bg-opacity-20;
	}

	.input {
		@apply rounded-primary p-2;
	}
}

.heroHeaderH1 {
	text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
}

.heroHeaderH2 {
	text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
}
